import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import messenger from '../messenger';
import client from '../lib/api';
import { isAccelPayMessage, getAddress } from '../lib/utils';

const DataFetcher = ({
  brandId,
  variantIds = [],
  doordashVariantIds = [],
  collectionIds = [],
  sentryTransaction,
  apiEnv,
  hasLocalDelivery = false,
  currency = 'USD',
}: any) => {
  const fetchedIds = useRef({});
  const api = new client(apiEnv);

  const fetchAvailability = async (
    zip?: string,
    lat?: number,
    lng?: number,
  ) => {
    if (variantIds?.length > 0) {
      const availabilityRes = await api.get(
        `/variants/availability?ids=${JSON.stringify(variantIds)}${
          zip ? `&zip=${zip}` : ''
        }`,
      );

      if (availabilityRes.ok) {
        const availability = (await availabilityRes.json()) || {};
        const final = Object.keys(availability).reduce((acc, variantId) => {
          if (fetchedIds.current[variantId]) {
            const { status, priceMin, priceMax } =
              availability[variantId][currency];

            acc[variantId] = {
              ...fetchedIds.current[variantId],
              inventoryStatus: status,
              priceMin,
              priceMax,
            };
          }
          return acc;
        }, {});

        messenger.variantData(window, '*', final);
      } else {
        console.error('error', await availabilityRes.text());
      }
    }

    if (doordashVariantIds.length) {
      const doordashAvailabilityRes = await api.get(
        `/variants/availability?retailerType=delivery_only&ids=${JSON.stringify(
          doordashVariantIds,
        )}${zip ? `&zip=${zip}` : ''}`,
      );
      if (doordashAvailabilityRes.ok) {
        const availability = (await doordashAvailabilityRes.json()) || {};
        const final = Object.keys(availability).reduce((acc, variantId) => {
          if (fetchedIds.current[variantId]) {
            const { status, priceMin, priceMax } =
              availability[variantId][currency];

            acc[variantId] = {
              ...fetchedIds.current[variantId],
              inventoryStatus: status,
              priceMin,
              priceMax,
            };
          }
          return acc;
        }, {});
        messenger.doordashVariantData(window, '*', final);
      } else {
        console.error('error', await doordashAvailabilityRes.text());
      }
    }

    if (hasLocalDelivery && lat && lng) {
      const gopuffRes = await fetch(
        `/api/gopuff/location?latitude=${lat}&longitude=${lng}`,
      );
      if (gopuffRes.ok) {
        const location = await gopuffRes.json();
        /** TODO */
      }
    }
  };

  const init = async (zip?: string, lat?: number, lng?: number) => {
    const sentryFetch = sentryTransaction?.startChild({
      op: 'fetch',
      description: 'variants',
    });
    const variantIdsToFetch = [...variantIds, ...doordashVariantIds].filter(
      id => !fetchedIds.current[id],
    );
    const collectionIdsToFetch = [...collectionIds].filter(
      id => !fetchedIds.current[id],
    );

    if (variantIdsToFetch.length > 0) {
      const variantsRes = await api.get(
        `/variants?ids=${JSON.stringify(variantIdsToFetch)}`,
      );
      if (variantsRes.ok) {
        const variants = await variantsRes.json();
        const result = variants.reduce((acc: any, variant: any) => {
          acc[variant.id] = variant;
          return acc;
        }, {});
        fetchedIds.current = { ...fetchedIds.current, ...result };
      } else {
        sentryFetch?.setStatus(sentryFetch?.UnknownError);
      }
    }
    fetchAvailability(zip, lat, lng);
    if (collectionIdsToFetch.length) {
      const collectionsRes = await api.get(
        `/brands/${brandId}/collections?ids=${JSON.stringify(
          collectionIdsToFetch,
        )}`,
      );
      if (collectionsRes.ok) {
        const collections = await collectionsRes.json();
        fetchedIds.current = {
          ...fetchedIds.current,
          ...collections.reduce((acc, collection) => {
            acc[collection.id] = collection;
            return acc;
          }, {}),
        };
        messenger.collectionData(
          window,
          '*',
          collections.reduce((acc, collection) => {
            acc[collection.id] = collection;
            return acc;
          }, {}),
        );
      }
    }

    sentryFetch?.finish();
  };

  useEffect(() => {
    const handleMessage = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      if (action === 'bc-add-address') {
        fetchAvailability(
          value?.address?.zip,
          value?.address?.lat,
          value?.address?.lng,
        );
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.apbrand.listeners?.push(handleMessage);
  }, []);

  useEffect(() => {
    const address = getAddress();
    init(address?.zip, address?.lat, address?.lng);
  }, [variantIds, collectionIds]);

  return null;
};

export default DataFetcher;
