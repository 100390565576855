import { h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import classNames from 'classnames';
import messenger from '../messenger';
import Button from './Button';
import { isAccelPayMessage } from '../lib/utils';

interface ButtonProps {
  listingId?: string;
  variantId?: string;
  collectionId?: string;
  config?: any;
  iframe?: any;
  openCart?: any;
  fullWidth?: boolean;
  children?: any;
  variantMap?: any;
}

const AddToCartButton = ({
  listingId,
  variantId,
  collectionId,
  config,
  iframe,
  openCart,
  fullWidth,
  children,
}: ButtonProps) => {
  const [available, setAvailable] = useState(true);
  const [preorder, setPreorder] = useState(false);
  const [loading, setLoading] = useState(true);
  const [variantMap, setVariantMap] = useState({});
  console.log('loading', loading);
  console.log('children', children);

  useEffect(() => {
    const handleMessage = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      if (action === 'bc-variant-data') {
        setVariantMap(value);
        setLoading(false);
      } else if (action === 'bc-collection-data') {
        // For collections, we don't need to wait for the variant data
        setLoading(false);
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.apbrand.listeners?.push(handleMessage);
  }, []);

  useEffect(() => {
    if (!((listingId && variantId) || collectionId)) {
      setAvailable(false);
      return;
    }

    if (variantId) {
      const variant = variantMap[variantId];
      if (variant) {
        setAvailable(
          variant.availabilityStatus !== 'unavailable' &&
            variant.inventoryStatus !== 'unavailable',
        );
        setPreorder(variant.availabilityStatus === 'preorder');
      }
    } else if (collectionId) {
      setAvailable(true);
    }
  }, [variantMap, variantId, collectionId]);

  const onClick = useCallback(() => {
    if (iframe && config) {
      if (collectionId) {
        messenger.addcollectiontocart(
          iframe.contentWindow,
          config?.url,
          collectionId,
        );
      } else if (listingId && variantId) {
        messenger.addtocart(
          iframe.contentWindow,
          config?.url,
          listingId,
          variantId,
        );
      }
    }
    if (openCart) {
      openCart();
    }
  }, [listingId, variantId, collectionId]);

  return (
    <Button
      config={config}
      isAvailable={available && !loading}
      className={classNames(
        'accelpay-btn',
        { 'accelpay-btn-oos': !available },
        ...(config?.button_classnames
          ? config?.button_classnames.split(',')
          : []),
      )}
      onClick={onClick}
      fullWidth={fullWidth}
    >
      {loading
        ? 'Checking...'
        : !!children && children !== 'Checking...'
          ? children
          : !available
            ? config?.oos_text || 'Sold Out'
            : preorder
              ? 'PRE-ORDER'
              : config?.button_text || 'ADD TO CART'}
    </Button>
  );
};

export default AddToCartButton;
