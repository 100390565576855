const toKlaviyoItem = item => ({
  ProductID: item.id,
  ProductName: item.title,
  Quantity: item.quantity,
  Price: (item.price || 0) / 100,
  ImageURL: item.images?.[0]?.path,
});

const toKlaviyoAddress = details => ({
  FirstName: details.name.split(' ')[0],
  LastName: details.name.split(' ')[1],
  Address1: details.line1,
  Address2: details.line2,
  City: details.city,
  Region: details.province,
  RegionCode: details.province,
  Country: details.country,
  CountryCode: details.country,
  Zip: details.zip,
  Phone: details.phone,
});

const getCheckoutURL = items =>
  `?items=${encodeURI(
    JSON.stringify(
      items.reduce((acc, item) => {
        acc[`${item.id}.${item.variant.id}`] = item.quantity;
        return acc;
      }, {}),
    ),
  )}&open=true`;

const calculateTotal = (estimate, tips) =>
  Object.values(estimate?.payment || {}).reduce(
    (sum: number, value: number) => sum + value,
    0,
  ) + tips;

export const klaviyoHandler = event => {
  try {
    const { action, value } = event.data;

    if (!/^bc\-.*/g.test(action) || typeof window.klaviyo === 'undefined') {
      return;
    }
    console.log('klaviyo action', action);

    if (action === 'bc-begin-checkout') {
      const { items, estimate } = value;
      window.klaviyo.push([
        'track',
        'Started Checkout',
        {
          $value: calculateTotal(estimate, 0) / 100,
          ItemNames: items.map(item => item.title),
          Items: items.map(toKlaviyoItem),
          CheckoutUrl: getCheckoutURL(items),
        },
      ]);
    } else if (action === 'bc-add-item') {
      const { items, added, estimate } = value;
      window.klaviyo.push([
        'track',
        'Added to Cart',
        {
          $value: calculateTotal(estimate, 0) / 100,
          AddedItemProductName: added.title,
          AddedItemProductID: added.id,
          AddedItemImageURL: added.images?.[0]?.path,
          AddedItemQuantity: 1,
          ItemNames: items.map(item => item.title),
          Items: items.map(toKlaviyoItem),
        },
      ]);
    } else if (action === 'bc-identify') {
      const { email } = value;
      window.klaviyo.push([
        'identify',
        {
          $email: email,
        },
      ]);
    } else if (action === 'bc-add-address') {
      const { address, estimate, items } = value;
      window.klaviyo.push([
        'track',
        'Added Shipping Info',
        {
          $first_name: address.firstName,
          $last_name: address.lastName,
          $phone_number: address.phone,
          $city: address.city,
          $region: address.state,
          $zip: address.zip,
          Brand_URL: location.hostname,
        },
      ]);
      // If user was not previously cookied we need to track the start of checkout
      window.klaviyo.push([
        'track',
        'Started Checkout',
        {
          $value: calculateTotal(estimate, 0) / 100,
          ItemNames: items?.map(item => item.title),
          Items: items?.map(toKlaviyoItem),
          CheckoutUrl: getCheckoutURL(items),
        },
      ]);
    } else if (action === 'bc-sale') {
      const { payload, items } = value;
      window.klaviyo.push([
        'track',
        'Placed Order',
        {
          customer_properties: {
            $email: payload.buyerDetail.email,
            $first_name: payload.buyerDetail.name.split(' ')[0],
            $last_name: payload.buyerDetail.name.split(' ')[1],
            $phone_number: payload.buyerDetail.phone,
            $address1: payload.buyerDetail.line1,
            $address2: payload.buyerDetail.line2,
            $city: payload.buyerDetail.city,
            $zip: payload.buyerDetail.zip,
            $region: payload.buyerDetail.province,
            $country: payload.buyerDetail.country,
          },
          properties: {
            $event_id: payload.id,
            OrderId: payload.id,
            ItemNames: items.map(item => item.title),
            Items: items.map(item => toKlaviyoItem(item)),
            BillingAddress: toKlaviyoAddress(payload.buyerDetail),
            ShippingAddress: toKlaviyoAddress(payload.shippingDetail),
          },
          time: new Date().toISOString(),
          $value: payload.total / 100,
        },
      ]);
    }
  } catch (e) {
    console.log('klaviyo error', e);
  }
};
